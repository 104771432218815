
import ManagementMixin from '@/mixins/ManagementMixin';

export default {
  mixins: [ManagementMixin],
  data: () => ({
    category: 'General',
    categories: ['General'],
    currentPage: 1
  })
};
