import Badge from '@/components/Management/Badge.vue';
import Button from '@/components/Common/Button.vue';
import Buttons from '@/components/Common/Buttons.vue';
import CardAction from '@/components/Management/CardAction.vue';
import Dialog from '@/components/Management/Dialog.vue';
import Drawer from '@/components/Common/Drawer.vue';
import FilterDropdown from '@/components/Common/FilterDropdown.vue';
import Input from '@/components/Common/Input.vue';
import Page from '@/components/Management/Page.vue';

export const components = {
  'm-btn': Button,
  'm-btns': Buttons,
  'm-input': Input,
  'm-badge': Badge,

  'm-page': Page,
  'm-dialog': Dialog,
  'm-drawer': Drawer,

  'm-filter-dropdown': FilterDropdown,
  'm-card-action': CardAction
};

export default {
  components
};
